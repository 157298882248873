<template>
 <div class="f-box">
     <div class="handle" v-if="tableData.length>0">
        <div class="h-img"><img src="@/assets/img/qing_02.png" alt=""></div>
        <div class="user">
          <span style="margin-right:0;">清点台编号：</span>
          <span style="margin-right:25px;">{{ taihao }}</span>
          <span style="margin-right:0;">清点责任人：</span>
          <span>{{username}}</span>
          <el-dropdown trigger="click">
                <span class="el-dropdown-link">
                  <i class="el-icon-arrow-down"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="showQingList">清点列表</el-dropdown-item>
                  <el-dropdown-item @click.native="back">退出登录</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
           <!-- <div class="h-btn" @click="showQingList">清点列表</div> -->
      </div>
     </div>
     <!-- <div class="no-table" v-if="tableData.length == 0 && !showQList">
          <img src="@/assets/img/hehe_03.png" alt="">
      </div> -->
    <div class="table-box" v-if="!showQList">
    <div :class="['no-table',iptfs ?'bg-filter':'']" v-if="tableData.length == 0 && !showQList">
        <img src="@/assets/img/hehe_03.png" alt="">
      </div>
      <div :class="['p-btn',iptfs?'center-btn':'']" v-if="tableData.length == 0">
            <el-input v-model="input" @focus="fcs" @blur="blu" placeholder="请输入抽签顺序号" style="width:350px" @keyup.enter.native="getQing">
                <el-button slot="append" icon="el-icon" @click="getQing" style="font-size: 20px;">搜索</el-button>
                <template slot="prepend"><span style="font-size: 20px;line-height: 20px;">VIP</span></template>
            </el-input>
        </div>
     <div class="s-btn" v-else>
         <el-input v-model="input" placeholder="请输入抽签顺序号" style="width:350px" @keyup.enter.native="getQing">
             <el-button slot="append" icon="el-icon" @click="getQing" style="font-size: 20px;">搜索</el-button>
             <template slot="prepend"><span style="font-size: 24px;line-height: 24px;">VIP</span></template>
         </el-input>
     </div>
    <div class="flex-box" v-if="tableData.length>0">
        <div style="margin-bottom:20px;margin-top:-20px;text-align: center;font-weight:bold;font-size:18px;" v-if="arr.length>0">
          <span style="color:#b7001d;">权利人：</span>
          <el-radio-group v-model="radios" @change="choose()">
            <el-radio v-for="(item,key,index) in Data" :label="key" :key="index">{{key}}</el-radio>
          </el-radio-group>
        </div>
        <div class="q-qing">
          <el-table
          :data="tableData1"
          border
          style="width: 100%" class="table1">
            <el-table-column prop="sort" label="抽签顺序号" align="center">
            </el-table-column>
            <el-table-column prop="contract" label="合同编号" align="center">
            </el-table-column>
            <el-table-column label="权利人名称"  align="center">
                <template>
                  {{radios}}
                </template>
            </el-table-column>
            <el-table-column label="清点时点" align="center">
              <template>
                {{time}}
              </template>
            </el-table-column>
            <el-table-column prop="room" label="总计抽签间数" align="center">
              <template>
                {{tao}}
              </template>
            </el-table-column>
          </el-table>
          <el-table
            :data="tableData"
            border
            style="width: 100%">
            <!-- <el-table-column  label="标段" align="center"  width="150px">
              <template slot-scope="scope">
                {{scope.row.house_type}}
              </template>
            </el-table-column> -->
            <el-table-column prop="block" label="项目及组团名称" align="center">
              <template slot-scope="scope">
                <div style="display:flex;justify-content: center;">
                  <el-select v-model="scope.row.block" placeholder="请选择" @change="opt1(scope.$index)" size="small" style="width:200px;">
                    <el-option
                      v-for="item in lou"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="unit" label="楼栋及楼座号" align="center">
              <template slot-scope="scope">
                <el-select v-model="scope.row.unit" placeholder="请选择" @change="opt2(scope.$index)" size="small" style="width:170px;">
                  <el-option
                    v-for="item in option[scope.row.block]"
                    :key="item"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column prop="room" label="房号" align="center">
              <template slot-scope="scope">
                <el-input v-model="scope.row.room"  size="small" style="width:130px;" maxlength="4" @input="cHouse(scope.row,scope.$index)">
                  <!-- <template slot="prepend">商业01层</template> -->
                  <!-- <template slot="append">号</template> -->
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="建筑面积（㎡）" align="center" width="190px">
              <template slot-scope="scope">
                {{scope.row.area}}
              </template>
            </el-table-column>
            <el-table-column label="所在规划地块" align="center" width="190px">
              <template slot-scope="scope">
                {{scope.row.land}}
              </template>
            </el-table-column>
            <el-table-column label="用途" align="center" width="190px">
              <template>
                住宅
              </template>
            </el-table-column>
          </el-table>
          <div class="page-btns">
            <div class="sure-btn" style="margin-right: 35px;" @click="sure">确认提交</div>
            <div class="sure-btn" @click="clearData">重置</div>
          </div>
        </div>
    </div>
    </div>
    <div class="table-box" v-else>
      <div class="flex-box">
              <el-button type="primary" style="margin-bottom: 20px;" @click="reback">返回</el-button>
              <el-button type="primary" style="margin-bottom: 20px" @click="daoChu">导出</el-button>
              <el-table
                  :data="Qlist"
                  border
                  style="width: 100%" class="table1">
                  <el-table-column label="抽签顺序号" prop="sort"  align="center">
                  </el-table-column>
                  <el-table-column label="权利人姓名" prop="name"  align="center">
                  </el-table-column>
                  <el-table-column label="合同号" prop="contract"  align="center">
                  </el-table-column>
                  <!-- <el-table-column label="组团" prop="block"  align="center">
                  </el-table-column>
                  <el-table-column label="楼栋及单元" prop="unit"  align="center">
                  </el-table-column> -->
                  <el-table-column label="回迁住宅房号" prop="room"  align="center">
                      <template slot-scope="scope">
                          {{scope.row.block}}{{scope.row.unit}}{{scope.row.room}}
                      </template>
                  </el-table-column>
                  <el-table-column label="用途"  align="center">
                    <template>住宅</template>
                  </el-table-column>
                  <el-table-column label="清点时点" prop="update_time"  align="center">
                  </el-table-column>
                  <el-table-column label="建筑面积（㎡）" prop="area"  align="center">
                  </el-table-column>
              </el-table>
              <el-pagination
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-size="10"
                layout="total, prev, pager, next, jumper"
                :total="QData.total">
              </el-pagination>
          </div>
    </div>
 </div>
</template>

<script>
let that;
export default {
  data () {
    return {
      currentPage:1,
      showQList:false,
      time:'',
      arr:[],
      radios:'',
      tao:'',
      Data:[],
      iptBTN:0,
      input:'',
      tableData1:[],
      tableData: [],
      option:[],
      lou:[],
      username:'',
      QData:[],
      Qlist:[],
      taihao:'',
      iptfs:false,
      //
      printT: {
        id: "printTest",
        popTitle: "",
        extraCss: "",
        extraHead: "",
      },
      userArr: [],
      contract: "",
    }
  },
  methods:{
    cHouse(val,idx){
      console.log(val,idx)
      if(val.unit != '' && val.block !='' && val.room.length >= 1){
          // that.getFM(val.block,val.unit,`商业01层${val.room}号`,idx)
          that.getFM(val.block,val.unit,`${val.room}`,idx)
      }
    },
    daoChu(){
      let uin = localStorage.getItem('uin')
      window.location.href = $baseUrl+`export/export_check?useruid=${uin}`
    },
    fcs(){
      this.iptfs = true
    },
    blu(){
      if(this.input == ''){
        this.iptfs = false
      }
    },
    reback(){
      this.showQList = false;
    },
    showQingList(){
      this.currentPage = 1
      this.getQingList();
      this.showQList = true
    },
    handleCurrentChange(val){
        this.currentPage = val
        this.getQingList();
    },
    // 获取清点列表
    getQingList(){
      this.$http.post('/index/check_list',{page:this.currentPage}).then(res=>{
          this.QData = res.data.data
          this.Qlist = res.data.data.list
      })
    },
    clearData(){
      this.arr = this.Data = this.tableData1 = this.tableData = this.lou = this.option = []
      this.arr = this.tableData = this.Data = [];
      this.input = this.radios = this.contract = "";
      this.radios = this.tao = this.input = ''
      this.iptfs = false
      this.getLou();
    },
    opt1(i){
      this.tableData[i].unit = this.tableData[i].room = this.tableData[i].area = this.tableData[i].house_type = this.tableData[i].land = ''
      if(this.option[this.tableData[i].block].length == 1){
        this.tableData[i].unit = this.option[this.tableData[i].block][0]
      }
    },
    opt2(i){
      this.tableData[i].room = this.tableData[i].area = this.tableData[i].house_type = this.tableData[i].land = ''
    },
    // 获取楼栋
    getLou(){
      this.lou = this.lou2 = []
      this.option = {}
      this.$http.post('/index/get_maps',{type:'build'}).then(res=>{
        console.log(res)
        this.option = res.data.data
        for(let key in res.data.data){
            this.lou.push({value: key,label: key})
        }
        console.log(this.lou)
      })
    },
    choose(){    
        this.tableData1 = [];
        this.tableData = this.Data[this.radios]
        this.tableData1.push(this.Data[this.radios][0])
        this.tao = this.tableData.length
    },
    getQing(){
      // this.tableData1 = this.tableData = this.Data = [];
      // this.radios = '';
      let dt = {'sort':'vip'+this.input}
      this.$http.post('/index/check_info',dt).then(res=>{
        if(res.data.code == 0){
          this.time = res.data.draw_time
          this.Data = res.data.data
          let i = 0;
          this.arr = []
          for(let key in res.data.data){
              i+=1;
              this.arr.push(key)
          }
          this.radios = this.arr[0]
          this.choose()
        }
      })
    },
    back(){
      this.$router.push({path:'/'})
      localStorage.clear();
    },
    sure(){
        let dt = {
          sort:this.tableData1[0].sort,
          name:this.radios,
          check_time:this.time,
          detail:this.tableData
        }
        this.$http.post('/index/check_info_submit',dt).then(res=>{
          if(res.data.code == 0){
            $success();
          }
        })
    },
    change(i){
        console.log(i)
        this.iptBTN = i
    },
    // 获取房型面积
    getFM(b,u,r,i){
      let dt = { block:b, unit:u, room:r}
      this.$http.post('/index/get_house_type',dt).then(res=>{
        this.tableData[i].area = res.data.area
        this.tableData[i].house_type  = res.data.house_type 
        this.tableData[i].land  = res.data.land 
      })
    },
  },
  created(){
    this.getLou()
    this.username = localStorage.getItem('name')
    this.taihao = localStorage.getItem('taihao')
  },
  beforeCreate(){
    that = this
  },
}

</script>
<style lang="less" scoped>
.f-box{
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
}
.table-box{
  flex: 1;
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
}
.handle{
  position: relative;
  background: #b5011d;
  height: 60px;
  line-height: 60px;
  padding:0 30px;
  color: gold;
  font-size: 24px;
  .user{
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translate(0,-50%);
    display: inline-flex;
    align-items: center;
    font-size: 16px;
    img{
      width: 36px;
      height: 36px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 10px;
    }
    span{
      margin-right: 15px;
    }
  }
}
.s-btn{
    margin: 30px auto 0;
    text-align: center;
}
.s-msg{
    padding: 30px 0;
    font-size: 16px;
    line-height: 22px;
    .s-title{
        position: relative;
        font-size: 18px;
        font-weight: bold;
        padding-bottom: 10px;
        line-height: 24px;
        margin-bottom: 5px;
        &::after{
            content: '';
            width: 150px;
            background-image: linear-gradient(to right,#000,#fff);
            height: 2px;
            position: absolute;
            left: 0;
            bottom: 0;
        }
    }
    .t-msg{
        font-size: 14px;
        line-height: 40px;
        .tag{
            &>div{
                display: inline-block;
                margin-right: 68px;
            }
            span:first-child{
                margin-right: 20px;
                display: inline-block;
            }
        }
    }
}
.s-radio{
    padding: 0 30px;
    margin-top: 10px;
}
.sure-btn{
    width: 120px;
    height: 40px;
    background: #b7001d;
    font-size: 14px;
    color: gold;
    text-align: center;
    border-radius: 6px;
    line-height: 40px;
    cursor: pointer;
}
/deep/tr td{
    padding: 5px 10px;
    text-align: center;
    white-space: nowrap;
}
.choose-btn{
    span{
        padding: 5px 10px;
        background: #7bb8f8;
        border-radius: 4px;
        margin-right: 10px;
        color: #fff;
        
    }
    margin: 20px;
}
.table1{
    line-height: 25px;
    border-collapse: collapse;
}
.flex-box{
    margin: 60px 40px 0;
}
/deep/.el-table thead th.is-leaf{
    color: gold;
    background-color: #b7001d!important;
}
/deep/.el-table thead.is-group th{
    color: gold;
    background-color: #b7001d!important;
}
.page-btns{
  display:flex;
  align-items: center;
  justify-content: center;
  margin:70px 0;
}
.h-btn{
  cursor: pointer;
  display: inline-block;
  width: 80px;
  height: 30px;
  position: absolute;
  top: 50%;
  transform: translate(0,-50%);
  color: #fff;
  font-weight: bold;
  right: 200px;
  text-align: center;
  line-height: 30px;
  font-size: 16px;
  border-radius: 4px 4px 0 0;
}
.no-table{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  line-height: 0;
  img{
    width:100%;
    height: 100%;
    object-fit: cover;
  }
}
.bg-filter{
  filter: blur(6px);
}
.p-btn{
  position: absolute;
  bottom: 120px;
  left: 50%;
  transform: translate(-50%,0);
}
.center-btn{
  bottom: 50%;
  transform: translate(-50%,-50%) scale(2.5);
}
.h-img{
  line-height: 0;
  height: 100%;
  overflow: hidden;
  img{
    height: 100%;
    margin-top: 4px;
  }
}
/deep/.el-pagination{
  margin: 20px 0;
}
::-webkit-scrollbar {
    display:none;
    width:0;
    height:0;
    color:transparent;
}
.s-tip {
  font-size: 14px;
  line-height: 24px;
  margin-top: 15px;
  .men1 {
    margin-top: 30px;
  }
}
.page-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  margin:70px 0;
}
/deep/.s-msg .el-input__inner {
  text-align: center;
}
/deep/.s-msg .tag .el-input__inner {
  text-align: left;
}
@media print {
  /deep/.not-print .el-input__inner {
    border: none !important;
  }
  /deep/.not-print .el-textarea__inner {
    border: none !important;
  }
  .show {
    display: block !important;
  }
}
.showTitle {
  font-size: 23px;
  font-weight: bold;
  text-align: center;
}
/deep/.el-textarea__inner {
  resize: none; //去掉右角下的斜线
  overflow-y: hidden; //解决在微信下滚动条还是显示问题
  color: #000;
  text-align: center;
  font-size: 16px;
}
.table1 {
  width: 100%;
}
table {
  border-collapse: collapse;
}
td {
  border: solid #000 1px;
  padding: 5px;
  text-align: center;
  white-space: nowrap;
}
th {
  padding: 5px;
}
.h-btn{
  cursor: pointer;
  display: inline-block;
  width: 80px;
  height: 30px;
  position: absolute;
  top: 50%;
  transform: translate(0,-50%);
  color: #fff;
  font-weight: bold;
  right: 200px;
  text-align: center;
  line-height: 30px;
  font-size: 14px;
  border-radius: 4px 4px 0 0;
}
/deep/.el-table thead th.is-leaf{
    color: gold;
    background-color: #b7001d!important;
}
/deep/.el-table thead.is-group th{
    color: gold;
    background-color: #b7001d!important;
}
tr{
  page-break-after:avoid;
}
tr th{
  font-weight: normal;
}
.table1 thead tr th{
  padding: 0;
  white-space: nowrap;
}
.table1 thead tr th div{
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-left: 1px solid;
  padding: 5px;
  border-right: 0;
}
.table1 thead tr th div.no-bottom{
  border-bottom: 0;
}
/deep/.el-textarea__inner{
    border: none !important;
    font-family:generic-family!important;
}
.h-img{
  line-height: 0;
  height: 100%;
  overflow: hidden;
  img{
    height: 100%;
    margin-top: 4px;
  }
}
/deep/.el-pagination{
  margin: 20px 0;
}
::-webkit-scrollbar {
    display:none;
    width:0;
    height:0;
    color:transparent;
}
@page{
  margin: 80px 0;
}
div,table,table>*{
  box-sizing: border-box;
}
</style>